<template>
  <ion-page id="page-content">
    <ion-modal
      @didDismiss="showRegisterTip = false"
      :is-open="showRegisterTip"
      css-class="tcc-modal"
      :animated="false"
    >
      <div class="model-box">
        <div class="ion-page">
          <div class="modal-content friend-modal">
            <p class="font-18 font-b main-color">{{ $t("login.GotoRegister") }}</p>
            <div class="btn-box" style="display: flex">
              <button class="btn" @click="showRegisterTip = false">
                {{ $t("general.No") }}
              </button>
              <button class="btn" @click="goRegister">{{ $t("general.Yes") }}</button>
            </div>
          </div>
        </div>
      </div>
    </ion-modal>
    <ion-content :fullscreen="true">
      <topbar title="Log in" :showBack="true" :noboder="true"></topbar>
      <div class="pc-box">
        <div class="pc-min-content">
          <img
            src="../assets/login.jpg"
            style="width: 100%"
            v-if="!$store.state.isPC"
            class="login-page-img"
          />

          <div class="content login-page">
            <div class="title-PC" v-if="$store.state.isPC">{{ $t("login.Login") }}</div>
            <div v-if="loginType == 2" class="form-item p-b-0">
              <label>{{ $t("login.Mobilenumber") }}</label>
              <!-- <div class="mobile-number item-table"> -->
              <!-- <div class="tdcell code">
                  <span class="Helveticaneue">+61</span><i class="icon-arrowLeft-2"></i>
                </div> -->
              <div class="input-box">
                <input
                  type="tel"
                  maxlength="10"
                  v-model="$store.state.userInfo.phone"
                  placeholder="Mobile number"
                  @input="errorFormat = false"
                />
              </div>
              <p class="tip red" v-if="errorFormat">
                {{ $t("login.MobileFormatError") }}
              </p>
              <div class="continueWithMobile"></div>
              <!-- </div> -->
            </div>
            <div v-if="loginType == 1" class="form-item p-b-0">
              <label>{{ $t("login.Email") }}</label>
              <div class="input-box">
                <input
                  type="text"
                  v-model="$store.state.userInfo.email"
                  placeholder="Email Address"
                  @input="inputEmail"
                />
              </div>
              <p class="tip red" v-if="errorFormat">{{ $t("login.EmailFormatError") }}</p>
              <div class="continueWithEmail">
                {{ $t("login.ContinueWithEmailText") }}
              </div>
            </div>
            <ion-button
              expand="block"
              class="Bold"
              :strong="
                $store.state.userInfo.phone || $store.state.userInfo.email ? true : false
              "
              @click="checkEvent"
              >{{ $t("login.Continue") }}</ion-button
            >
            <div class="or-hr">
              <span>{{ $t("login.Or") }}</span>
            </div>
            <div class="btn-plain" v-if="loginType == 2" @click="changLoginType(1)">
              <i class="icon-mail"></i>
              <span class="Medium">{{ $t("login.ContinuewithEmail") }}</span>
            </div>
            <div class="btn-plain" v-if="loginType == 1" @click="changLoginType(2)">
              <i class="icon-mobile"></i>
              <span class="Medium">{{ $t("login.ContinuewithMobile") }}</span>
            </div>
            <div v-if="loginType == 2" class="continueWithEmail">
              {{ $t("login.IfYouRegistered") }}
            </div>
            <div class="sharingRmailTip">{{ $t("login.IfYouAre") }}</div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton, IonModal } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
// import { NativeBiometric } from "capacitor-native-biometric";

import config from "../config";
export default {
  name: "LoginPage",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonButton, IonModal },
  data() {
    return {
      loginType: 1,
      ispassword: true,
      phone: "",
      password: "",
      phoneAreaCode: "+61",
      email: "",
      rightTitle: "",
      showRegisterTip: false,
      errorFormat: false,
      canSendS: true,
      canSendE: true,
    };
  },
  methods: {
    goRegister() {
      this.showRegisterTip = false;
      this.$router.push({
        path: "/signup",
      });
      this.sendGAEvent("Click", "Log in", "Go Signup");
    },
    forgetEvent() {
      this.$router.push({
        path: "/forgotPassword/" + this.loginType,
      });
      this.sendGAEvent("Click", "Log in", "Forgot Password");
    },
    // facebookLoginEvent() {
    //   this.facebookLogin(this.fbLogin);
    //   this.sendGAEvent("Click", "Log in", "Facebook");
    // },
    async fbLogin(obj) {
      const objdata = this.getAuthObj();
      objdata.snsId = obj.userId;
      objdata.snsType = 1;
      objdata.snsAccessToken = obj.token;
      objdata.loginType = 3;
      if (this.$store.state.appVersion) {
        objdata.loginSource = 1; //app
      } else {
        objdata.loginSource = 2; //web
      }
      objdata.apiUrl = this.apiConfig.login;
      const data = await this.$store.dispatch("apiEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$store.state.form.token = data.data.result.token;
      this.getUserPofileEvent();
    },
    // async performBiometricVerificatin() {
    //   const result = await NativeBiometric.isAvailable();

    //   if (!result.isAvailable) return;

    //   // const isFaceID = result.biometryType == BiometryType.FACE_ID;
    //   console.log(result.biometryType);
    //   alert(result.biometryType);

    //   const verified = await NativeBiometric.verifyIdentity({
    //     reason: "For easy log in",
    //     title: "Log in",
    //     subtitle: "Maybe add subtitle here?",
    //     description: "Maybe a description too?",
    //   })
    //     .then(() => true)
    //     .catch(() => false);

    //   if (!verified) return;

    //   const credentials = await NativeBiometric.getCredentials({
    //     server: "www.example.com",
    //   });
    //   alert(credentials);
    // },
    inputEmail() {
      this.errorFormat = false;
      this.$store.state.userInfo.email = this.$store.state.userInfo.email
        .trim()
        .toLowerCase();
    },
    changLoginType(type) {
      this.loginType = type;
      this.errorFormat = false;
      this.$store.state.userInfo.phone = "";
      this.$store.state.userInfo.email = "";
      this.phone = "";
      this.email = "";
    },
    async checkEvent() {
      let type = this.loginType == 1 ? "email" : "phone";
      if (!this.$store.state[`${type}Patrn`].test(this.$store.state.userInfo[type])) {
        this.errorFormat = true;
        return;
      }
      const objdata = this.getAuthObj();
      if (this.loginType == 2) {
        objdata.phone = this.$store.state.userInfo.phone;
        objdata.phoneAreaCode = this.$store.state.userInfo.phoneAreaCode;
      } else if (this.loginType == 1) {
        this.$store.state.userInfo.email = this.$store.state.userInfo.email
          .trim()
          .toLowerCase();
        objdata.email = this.$store.state.userInfo.email;
      }
      objdata.type = this.loginType;
      objdata.apiUrl = this.apiConfig.checkIsExistAccount;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (data.data.result.existAccount && !data.data.result.needVerify) {
        this.$router.push({
          path: "/password/" + this.loginType,
        });
      } else if (data.data.result.existAccount && data.data.result.needVerify) {
        this.$store.state.needVerify = data.data.result.needVerify;
        if (data.data.result.verifyUserInfo) {
          this.$store.state.verifyUserInfo = data.data.result.verifyUserInfo;
        }
        this.confirmEvent();
      } else {
        this.showRegisterTip = true;
      }
    },
    async sendSmsEvent() {
      if (!this.canSendS) return;
      this.canSendS = false;
      const objdata = this.getAuthObj();
      objdata.phone = this.$store.state.userInfo.phone;
      objdata.phoneAreaCode = this.$store.state.userInfo.phoneAreaCode;
      objdata.apiUrl = this.apiConfig.sendSms;
      objdata.verifyType = 5;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendS = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$store.state.userInfo.registerType = 2;
      this.$router.push({
        path: "/verifyCode/1/2",
      });
    },
    async sendEmailEvent() {
      if (!this.canSendE) return;
      this.canSendE = false;
      const objdata = this.getAuthObj();
      objdata.email = this.$store.state.userInfo.email;
      objdata.apiUrl = this.apiConfig.sendVerifyEmail;
      objdata.verifyType = 5;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendE = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$store.state.userInfo.registerType = 1;
      this.$router.push({
        path: "/verifyCode/1/1",
      });
    },
    confirmEvent() {
      if (this.loginType == 2) {
        this.sendSmsEvent();
        this.sendGAEvent("Click", "Log in", "SMS");
      } else {
        this.sendEmailEvent();
        this.sendGAEvent("Click", "Log in", "Email");
      }
    },
  },
  //   ionViewWillLeave() {
  //     this.$store.state.userInfo.email = "";
  //     this.$store.state.userInfo.phone = "";
  //   },
  ionViewWillEnter() {
    this.$store.state.needVerify = false;
    this.loginType = 1;
    this.ispassword = true;
    this.phone = "";
    this.password = "";
    this.phoneAreaCode = "+61";
    this.$store.state.userInfo.phoneAreaCode = "+61";
    this.email = "";
    this.rightTitle = "";
    if (this.$store.state.firstPage == 2) {
      this.rightTitle = this.$t("register.Registration");
    }
    this.$store.state.exitApp = false;
    this.sendGAPageView("Log in");
    this.getMerchantDetail();
  },
};
</script>
